import { createApp } from 'vue'
import App from './App.vue'
import i18n from './language'
import './scss/reset.scss'
import routes from './router'
const app = createApp(App)
app.config.globalProperties.$i18n = i18n
app.config.globalProperties.$t = i18n.global.t
app.use(i18n).use(routes).mount('#app')

