import { createRouter, createWebHashHistory } from "vue-router";
const routes = createRouter({
    history:createWebHashHistory(),
    routes: [
        {
            path:'/',
            component:() => import('pages/home.vue')
        },
        {
            path:'/product/:name?',
            name:'productDetail',
            component:() => import('pages/product.vue')
        }
    ]
})

export default routes

