<template>
<div class="main-content-header">
  <div class="nav-list">
    <div class="logo">
      <img src="~assets/logo-light.png" style="height:55px" alt="普惠医疗"/>
    </div>
    <ul>
      <li :class="{'active':$route.path === '/'}">
        <router-link to="/">{{$t('index.pagetitle')}}</router-link>
      </li>
      <li :class="{'active':$route.path.indexOf('/product') > -1}">
        <router-link to="/product">{{$t('product.pagetitle')}}</router-link>
      </li>
    </ul>
  </div>
  <div class="switch-language">
      <span>{{$t('index.switchLanguage')}}</span>
      <span class="arrow"></span>
      <ul class="nav-dropdown">
        <li @click="changeLanguage('zh')">{{$t('index.Chinese')}}</li>
        <li @click="changeLanguage('en')">{{$t('index.English')}}</li>
      </ul>
  </div>
</div>
<div class="main-conetnt-swiper">
  <div class="swiper" id="swiper-img">
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <img src="~assets/slide1.jpg"/>
      </div>
      <div class="swiper-slide">
        <img src="~assets/slide2.jpg"/>
      </div>
      <div class="swiper-slide">
        <img src="~assets/slide3.jpg"/>
      </div>
      <div class="swiper-slide">
        <img src="~assets/slide4.jpg"/>
      </div>
    </div>
    <div class="swiper-button-prev" />
    <div class="swiper-button-next" />
    <div class="swiper-pagination"/>
  </div>
</div>
<router-view />
<div class="main-content-box bottom">
  <div class="content">
    <div class="logo">
      <img src="~assets/logo.png" width="180"/>
    </div>
    <ul>
      <li>
        <label>{{$t('index.address.title')}}：</label>{{$t('index.address.message')}}
      </li>
      <li>
        <label>{{$t('index.mailBox.title')}}：</label>customer_service@purwell.cn
      </li>
      <li>
        <label>{{$t('index.phone.title')}}：</label>0755 23218701
      </li>
    </ul>
  </div>
  <div class="footer">
    <div>
      Copyright © 深圳普汇医疗科技有限公司 All Rights Reserved.
    </div>
  </div>
</div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'
import {  getCurrentInstance,onMounted,provide } from 'vue'
export default {
  name: 'App',
  setup(){
    provide('Swiper',Swiper)
    const { appContext:{ app:{ config:{globalProperties}} } } = getCurrentInstance()
    const $i18n = globalProperties.$i18n
    const changeLanguage = (lang) => {
      $i18n.locale = lang
    }
    const initSwiper = () => {
      new Swiper('#swiper-img',{
        autoplay:true,
        speed:600,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        observer:true,
        observeParents:true
      })
    }

    onMounted(initSwiper)
    return{
      changeLanguage
    }
  }
}
</script>
<style lang="scss">
a{
  text-decoration: none;
  color: inherit;
}
.main-content-header{
  height: 55px;
  display: flex;
  margin: auto;
  align-items:center;
  justify-content: space-between;
  .logo{
    width: fit-content;
    height: 100%;
    display: flex;
    align-items: center;
    display: inline-block;
  }
  .nav-list{
    display: flex;
    align-items: center;
    ul{
      margin-left: 100px;
      display: inline-block;
    }
    li{
      margin-right: 60px;
      display: inline-block;
      font-size: 16px;
      &.active{
        // color: #5592b3;
        color: #3979bd;
        font-weight: 700;
      }
    }
  }
  .switch-language{
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    height: 100%;
    &:hover{
      ul{
        display: block;
      }
    }
    ul{
      display: none;
      position: absolute;
      top: 50px;
      z-index: 10;
      right: -15px;
      left: -15px;
      box-sizing: border-box;
      position: absolute;
      background-color: #fff;
      padding: 10px 0;
      border: 1px solid #ddd;
      border-bottom-color: #ccc;
      text-align: left;
      border-radius: 4px;
      white-space: nowrap;
      li{
        text-align: center;
        line-height: 1.8em;
        &:hover{
          color: #187fc3;
        }
      }
    }
    .arrow{
      width: 0;
      height: 0;
      margin-left: 5px;
      display: inline-block;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 5px solid #4f5959;
    }
  }
}
.swiper{
  overflow: hidden;
  position: relative;
}
.swiper-slide{
  width: 100%;
}
.main-conetnt-swiper .swiper-slide img{
  width: 100%;
}
.main-content-box{
  padding: 50px;
  text-align: left;
  .box-title{
    font-size:36px;
    color:#000000;
    text-align: left;
    display: inline-block;
    font-weight: 600;
    margin-bottom: 50px;
    &::after{
      width:100%;
      background: #187fc3;
      height: 5px;
      display: block;
      content:'';
      margin-top: 5px;
    }
  }
  .box-decs{
    margin-bottom: 50px;
    line-height: 1.5;
  }
  .desc{
    color: #666666;
    line-height: 22px;
  }
  .bottom{
     display: flex;
    .panel{
      flex: 1;
      padding: 20px;
      box-sizing: border-box;
      background-color: rgba(243, 243, 243, 1);
      & + .panel{
        margin-left: 20px;
      }
      p{
        font-size: 20px;
        display: block;
        &.desc{
          font-size: 14px;
          margin-top: 10px;
        }
      }
    }
  }
  .product-list{
      display: flex;
      li{
        // flex: 1;
        width: 25%;
        & + li{
          margin-left: 20px;
        }
        .block{
          cursor: pointer;
          position: relative;
          &:hover{
            div{
               transform: scale(1);
            }
          }
          div{
            position: absolute;
            width: 100%;
            height: 100%;
            top:0;
            left: 0;
            background: rgba(13,124,196,0.85);
            transform: scale(0);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 22px;
            color: #ffffff;
            transition: transform .3s ease;
          }
        }
        img{
          width: 100%;
          height: auto;
          box-shadow: 0 0 6px rgba(150, 150, 150,0.2);
        }
        .title{
            color: #404040;
            font-size: 20px;
            margin: 30px 0 10px 0;
            text-align: center;
            font-weight: 600;
        }
        .type{
            text-align: center;
            font-size: 16px;
        }
      }
  }
  &.bottom{
    padding-bottom: 0;
    overflow: hidden;
    background: #222;
    color: #ffffff;
    ul{
      margin-top: 20px;
      li{
        margin-bottom: 10px;
      }
    }
    .footer{
      margin: 50px auto 10px auto;
      text-align: center;
    }
  }
  .swiper-product-pagination{
    text-align: center;
  }
}
.swiper-button-prev{
  transform: translateX(20px);
}
.swiper-button-next{
  transform: translateX(-20px);
}
.swiper-button-prev,.swiper-button-next{
  &::after{
    color: #ffffff;
  }
}
.swiper-pagination-bullet{
  width: 16px;
  height: 16px;
}
@media screen and (min-width:769px){
  .main-content-header{
    width: 1200px;
  }
}
</style>